<template>
  <div id="UserManagement">
    <el-form size="mini" :inline="true">
      <el-form-item>
        <el-button-group>
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="addShow = true"
          ></el-button>
          <el-button
            type="primary"
            icon="el-icon-delete"
            @click="
              remove($constant.method.getArrayValueByKey(selectionTableData))
            "
          ></el-button>
          <el-button
            type="primary"
            icon="el-icon-refresh-right"
            @click="$router.go(0)"
          ></el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <el-input
          placeholder="请输入账户"
          size="mini"
          v-model="where.username"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          size="mini"
          type="primary"
          plain
          icon="el-icon-search"
          @click="getList"
          >搜索</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      size="mini"
      border
      :data="tableData"
      @selection-change="
        selection => {
          selectionTableData = selection;
        }
      "
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="ID" type="index" width="50"></el-table-column>
      <el-table-column label="账号" prop="username"></el-table-column>
      <el-table-column label="管理员">
        <template slot-scope="scope">
          <el-switch
            @change="
              value => {
                modify(scope.row.id, value);
              }
            "
            v-model="scope.row.is_admin"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        label="创建时间"
        prop="add_time"
        width="140"
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            plain
            @click="remove([scope.row.id])"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="
        size => {
          where.limit = size;
          getList();
        }
      "
      @current-change="
        page => {
          where.page = page;
          getList();
        }
      "
      :current-page="where.page"
      :page-sizes="$constant.web.pageSizes"
      :page-size="where.limit"
      :pager-count="5"
      layout="total, sizes, prev, pager, next"
      :total="where.total"
    >
    </el-pagination>

    <el-dialog title="新增" :visible.sync="addShow" width="350px">
      <el-form size="mini">
        <el-form-item>
          <el-switch
            v-model="form.isAdmin"
            active-text="管理员"
            inactive-text="用户"
          ></el-switch>
        </el-form-item>
        <el-form-item>
          <el-input
            size="mini"
            placeholder="请输入账号"
            v-model="form.username"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            size="mini"
            placeholder="请输入密码"
            type="password"
            v-model="form.password"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            size="mini"
            placeholder="请确认密码"
            type="password"
            v-model="form.confirmPassword"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" plain @click="add"
            >提交</el-button
          >
          <el-button size="mini" @click="addShow = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "UserManagement",
  data() {
    return {
      addShow: false,
      form: {
        isAdmin: false,
        username: "",
        password: "",
        confirmPassword: ""
      },
      where: {
        username: "",
        page: 1,
        limit: 0,
        total: 0
      },
      tableData: [],
      selectionTableData: []
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 增加
    add() {
      if (
        !this.form.username ||
        !this.form.password ||
        !this.form.confirmPassword
      ) {
        this.$message.error("请完善表单");
        return false;
      }
      if (this.form.password != this.form.confirmPassword) {
        this.$message.error("密码不一致");
        return false;
      }
      if (this.form.username.length < 5) {
        this.$message.error("账户不能少于5位");
        return false;
      }
      if (this.form.password.length < 6) {
        this.$message.error("密码不能少于6位");
        return false;
      }
      this.$http({
        url: "/user/add",
        method: "POST",
        data: this.form
      }).then(() => {
        this.$message({
          message: "添加成功",
          type: "success"
        });
        this.addShow = false;
        this.form = {
          isAdmin: false,
          username: "",
          password: "",
          confirmPassword: ""
        };
        this.getList();
      });
    },

    // 删除
    remove(ids) {
      this.$confirm("确定删除").then(() => {
        this.$http({
          url: "/user/remove",
          method: "POST",
          data: {
            ids: ids
          }
        }).then(() => {
          this.$message({
            message: "删除成功",
            type: "success"
          });
          this.getList();
        });
      });
    },

    // 修改管理员
    modify(id, isAdmin) {
      this.$http({
        url: "/user/modify_admin",
        method: "POST",
        data: {
          id: id,
          isAdmin: Number(isAdmin)
        }
      }).then(() => {
        this.$message({
          message: "修改成功",
          type: "success"
        });
      });
    },

    // 得到数据列表
    getList() {
      this.$http({
        url: "/user/list",
        method: "POST",
        data: this.where
      }).then(response => {
        this.where.total = response.count;
        this.tableData = response.list;
      });
    }
  }
};
</script>

<style scoped></style>
